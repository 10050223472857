import React from 'react';
import './stats.css';
import acres from '@homeAssets/stats/acres.svg';
import co2 from '@homeAssets/stats/co2.svg';
import waterSaved from '@homeAssets/stats/water-saved.svg';
import farmer from '@ourProjectAssets/stats/farmer.svg';

import CountUp from 'react-countup';

export default function Stats() {
    return (
        <>
            <section className=" bg-[#001A18] stats-wrapper">
                <div className="blade-padding-sm">
                    <h2 className="h2 text-gradient text-center lg:w-[50%] w-11/12 2xl:w-[35%] mx-auto leading-tight py-5">
                        Impact at scale
                    </h2>
                    <div className="blade-padding-xs px-3">
                        <div className="flex flex-wrap px-3 py-14 max-w-md md:flex-row flex-col items-center  justify-around md:max-w-screen-2xl mx-auto  md:w-11/12  cards-wrapper rounded-3xl gap-12 sm:gap-0">
                            <div className="grow-0 mx-auto  shrink-0 grid  justify-center text-center sm:text-left sm:justify-start">
                                <div className="py-5 md:py-10  px-2 lg:px-0 text-center">
                                    <img
                                        className="h-20 object-contain"
                                        src={farmer}
                                        alt=""
                                    />
                                    <div className=" pt-1 md:pt-4">
                                        <CountUp
                                            className="h5 text-[#FFFFFF] "
                                            start={99000}
                                            end={100000}
                                            suffix={' +'}
                                            duration={6}
                                            separator=","
                                        />
                                    </div>
                                    <h6 className="h5 text-[#F9AC00] fw-500  pt-2 md:pt-2 text-center">
                                        Farmers Onboarded
                                    </h6>
                                </div>
                            </div>
                            <div className="grow-0 mx-auto  shrink-0 grid  justify-center text-center sm:text-left sm:justify-start">
                                <div className="py-5 md:py-10  px-2 lg:px-0 text-center">
                                    <img
                                        className="h-20 object-contain"
                                        src={acres}
                                        alt=""
                                    />
                                    <div className=" pt-1 md:pt-4">
                                        <CountUp
                                            className="h5 text-[#FFFFFF] "
                                            start={436318}
                                            end={437318}
                                            suffix={' +'}
                                            duration={6}
                                            separator=","
                                        />
                                    </div>
                                    <h6 className="h5 text-[#F9AC00] fw-500  pt-2 md:pt-2 text-center">
                                        Hectares covered
                                    </h6>
                                </div>
                            </div>
                            <div className=" grow-0 shrink-[2]  mx-auto card-divide-left  grid sm:justify-start justify-center text-center sm:text-left">
                                <div className=" py-5 md:py-10  px-2 lg:px-0">
                                    <img
                                        className="h-20 object-contain"
                                        src={co2}
                                        alt=""
                                    />
                                    <div className="pt-1 md:pt-4  text-center">
                                        <CountUp
                                            className="h5 text-[#FFFFFF] "
                                            start={1999000}
                                            end={2000000}
                                            suffix={' +'}
                                            duration={6}
                                            separator=","
                                        />
                                    </div>
                                    <h6 className="h5 text-[#F9AC00] fw-500  pt-2 md:pt-2  text-center">
                                        Tonnes of CO
                                        <sub>2</sub>e sequestered
                                    </h6>
                                </div>
                            </div>
                            {/* <div className="grow-0 shrink mx-auto grid sm:justify-start justify-center  text-center sm:text-left">
                                <div className=" py-5 md:py-10  px-2 lg:px-0">
                                    <img
                                        className="h-20 object-contain"
                                        src={waterSaved}
                                        alt=""
                                    />
                                    <div className="pt-1 md:pt-4 text-center">
                                        <CountUp
                                            className="h5 text-[#FFFFFF]  "
                                            start={0}
                                            end={750}
                                            suffix={' Million litres'}
                                            duration={6}
                                            separator=","
                                        />
                                    </div>
                                    <h6 className="h5 text-[#F9AC00] fw-500   pt-2 md:pt-2  text-center">
                                        Litres of water saved
                                    </h6>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="blade-padding-lg mt-20"></div>
                </div>
            </section>
        </>
    );
}
