import React, { useEffect, useRef, useState } from 'react';
import Founders from './founders/founders';
import Employee from './Employee/Employee';
import Team from './team/team';
import JoinUs from './joinUs/joinUs';
import ClimateCrisis from './climateCrisis/crisis';
import TeamIntro from './teamIntro/intro';
import Carousel from './carousel/carousel';
import Timeline from './timeline';
import { BannerSecondary } from '../../modules/molecules';
import thumbnail from '@thumbnailsAssets/about.jpg';
import { CDN_URL } from '../../config';
import founders from '@appData/founders';
import {
    climateFinaceMembers,
    techMembers,
    scienceMembers,
    operationMembers,
    partnerShipMembers,
    adminFinanceLegal,
    remoteSensingMembers,
    dataQualityAssurance,
} from '../../modules/data/members';

const memberAssets = [
    {
        title: 'Climate Finance',
        source: climateFinaceMembers,
    },
    {
        title: 'Science',
        source: scienceMembers,
    },
    {
        title: 'Remote Sensing',
        source: remoteSensingMembers,
    },
    {
        title: 'Engineering',
        source: techMembers,
    },
    ,
    {
        title: 'Data Quality Assurance',
        source: dataQualityAssurance,
    },
    {
        title: 'Carbon Projects',
        source: partnerShipMembers,
    },
    {
        title: 'Project Operations',
        source: operationMembers,
    },
    {
        title: 'Corporate',
        source: adminFinanceLegal,
    },
];
export default function AboutScreen() {
    const elementRef = useRef();

    return (
        <div className="bg-cream">
            <BannerSecondary
                heading="Rooted in agriculture, inspired by nature, committed to carbon removal at scale"
                videoLink={CDN_URL + '/about_us.mp4'}
                thumbnail={thumbnail}
            />
            <Timeline />
            <h2 className="h1 text-gradient-dark  font-medium blade-padding-sm container">
                Our Team
            </h2>
            <Founders
                items={founders}
                title="Founders"
                sideBar={true}
                cardType={'primary'}
            />
            {memberAssets.map((e) => (
                <Founders
                    items={e.source}
                    title={e.title}
                    cardType={'secondary'}
                    key={e.title}
                />
            ))}

            <div ref={elementRef}></div>
            <Carousel />
            <JoinUs />
        </div>
    );
}
