import React, { useState } from 'react';
import './founders.css';
import arrowLight from '@iconsAssets/arrow-light.svg';
import Sidebar from './sidebar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const PrimaryTeamCard = ({ member, idx, handleIdx, sideBar }) => {
    const { name, designation, linkedinProfile, displayPic } = member;

    return (
        <div className="basis-96  grow-0 shrink flex flex-col  rounded-xl  md:rounded-2xl overflow-hidden">
            <div className="basis-60 h-full grow-0 shrink">
                <img
                    src={displayPic}
                    alt="team member"
                    className="h-full w-full object-cover object-top"
                />
            </div>
            <div className="card-content-wrapper grow shrink basis-32">
                <div className="flex flex-col gap-0 flex-nowrap md:gap-1 pt-4 md:pt-5 lg:pt-6 xl:pt-7 pb-4 md:pb-8 lg:pb-12 xl:px-6 px-4 2xl:px-8 text-white  ">
                    <h5
                        onClick={() => handleIdx(idx)}
                        className="h4 fw-600 hover:text-yellow cursor-pointer transition-all duration-300"
                    >
                        {name}
                    </h5>
                    <span className="text-base p">{designation} </span>
                    {
                        <div className="mt-4 md:mt-5 xl:mt-3 flex items-center justify-between gap-3 flex-nowrap">
                            <a
                                href={linkedinProfile}
                                className="fill-white hover:fill-gray  mr-auto "
                                rel="noreferrer"
                                target="_blank"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    className="h-8 w-8 fill-inherit"
                                >
                                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                                </svg>
                            </a>
                            {sideBar && (
                                <img
                                    onClick={() => handleIdx(idx)}
                                    src={arrowLight}
                                    className="h-9 w-9 cursor-pointer"
                                    alt="arrow icon"
                                />
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
const SecondaryTeam = ({ member, idx, handleIdx, sideBar }) => {
    const { name, designation, displayPic } = member;
    const [activeIndex, setActiveIndex] = useState(-1);
    const onMouseEnter = () => {
        setActiveIndex(idx);
    };
    const onMouseLeave = () => {
        setActiveIndex(-1);
    };
    return (
        <div
            className={`grow-0 shrink flex flex-col rounded-xl md:rounded-2xl overflow-hidden`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className={`${'basis-10'} h-full grow-0 shrink `}>
                <LazyLoadImage
                    alt={name + 'image'}
                    effect="blur"
                    delayTime={500}
                    src={displayPic} // use normal <img> attributes as props
                    className="h-full w-full object-cover object-center inline"
                />
            </div>
            <div className={`card-content-wrapper grow shrink ${'basis-4'} `}>
                <div className="flex flex-col gap-0 flex-nowrap md:gap-1 pt-4 md:pt-5 lg:pt-6 xl:pt-7 pb-4  px-3  2xl:px-8 text-white">
                    <div className="inline-block">
                        <h5 className="h4 fw-600 hover:text-yellow cursor-pointer transition-all duration-300 whitespace-nowrap">
                            {name.length > 15 ? name.slice(0, 15) + '..' : name}
                        </h5>
                        <div
                            className={`
                                absolute 
                                
                                p-2 
                                rounded-xl 
                                bg-primary 
                                transform
                                transition-all
                                duration-300
                                ease-in-out
                                ${
                                    activeIndex === idx
                                        ? 'opacity-100 translate-y-0 visible'
                                        : 'opacity-0 -translate-y-2 invisible'
                                }
                                h4
                                text-sm
                                font-semibold
                               z-[200]
                               whitespace-nowrap
                                shadow-lg
                            `}
                        >
                            <span className="text-yellow">{name}</span>
                        </div>
                    </div>
                    <span className="text-sm ">{designation}</span>
                    <div className="mt-4 md:mt-5 xl:mt-3 flex items-center justify-between gap-3 flex-nowrap">
                        {sideBar && (
                            <img
                                onClick={() => handleIdx(idx)}
                                src={arrowLight}
                                className="h-9 w-9 cursor-pointer"
                                alt="arrow icon"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const computeLayoutClass = (cardType) => {
    switch (cardType) {
        case 'primary':
            return 'founders-wrapper grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:gap-10 gap-4';
        case 'secondary':
            return 'founders-wrapper grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-4 gap-2';
        default:
            throw 'Invalid cardType';
    }
};

export default function Founders({ title, items, sideBar = false, cardType }) {
    const [sidebarIdx, setSidebarIdx] = useState(-1);

    const handleClose = () => setSidebarIdx(-1);
    const handleNext = () =>
        setSidebarIdx((prev) => Math.min(items.length, prev + 1));
    const handlePrev = () => setSidebarIdx((prev) => Math.max(0, prev - 1));
    const handleIdx = (index) => setSidebarIdx(index);
    return (
        <>
            {sideBar && (
                <Sidebar
                    idx={sidebarIdx}
                    close={handleClose}
                    next={handleNext}
                    prev={handlePrev}
                />
            )}
            <div className="container">
                <div
                    className={`mx-auto px-0 pb-10 ${
                        cardType === 'primary' ? 'pt-[2.5rem]' : 'pt-[5rem]'
                    }`}
                >
                    <h4 className="h4 font-semibold pb-2 text-gradient-dark md:pb-3">
                        {title}
                    </h4>
                    <div className="w-full h-3 border-dashed border-t-gray border-t-2 relative">
                        <div className="h-2 rounded-full bg-yellow absolute left-0 -top-[6px] w-32"></div>
                    </div>
                </div>
                <div className={computeLayoutClass(cardType)}>
                    {items.map((elem, index) =>
                        cardType === 'primary' ? (
                            <PrimaryTeamCard
                                idx={index}
                                member={elem}
                                key={index}
                                Sidebar={Sidebar}
                                handleIdx={handleIdx}
                            />
                        ) : (
                            <SecondaryTeam
                                idx={index}
                                member={elem}
                                key={index}
                                Sidebar={Sidebar}
                            />
                        )
                    )}
                </div>
            </div>
        </>
    );
}
