import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import './banner.css';

const Banner = ({
    videoLink,
    heading,
    subHeading,
    subHeading_1,
    subHeading_2,
    btnLink,
    navhashLink,
    scrollArrow,
    pb,
    fullScreen,
    thumbnail,
    reverseHeading = false,
    mediaType = 'video',
}) => {
    return (
        <>
            <div className={`h-fit banner-wrapper`}>
                {mediaType === 'video' ? (
                    <video
                        autoPlay
                        loop
                        muted
                        className={`object-cover md:h-screen w-full ${
                            fullScreen ? 'h-screen' : 'h-[40rem]'
                        }`}
                        poster={thumbnail}
                    >
                        <source src={videoLink} type="video/mp4"></source>
                    </video>
                ) : (
                    <img
                        src={thumbnail}
                        alt="banner-image"
                        className={`object-cover md:h-screen w-full ${
                            fullScreen ? 'h-screen' : 'h-[40rem]'
                        }`}
                    />
                )}
                <div className={`${pb ? `pb-${pb}` : ''} banner-text-wrapper`}>
                    {/* Banner Section Heading */}

                    {reverseHeading ? (
                        <div className="flex flex-col items-center gap-4 text-white">
                            {subHeading}
                            <h1 className="text-white xl:max-w-6xl max-w-5xl h1 text-center font-medium px-4 leading-tight">
                                {heading}
                            </h1>
                        </div>
                    ) : (
                        <>
                            <h1 className="text-white xl:max-w-6xl max-w-5xl h1 text-center font-medium px-4 leading-tight">
                                {heading}
                            </h1>

                            {/* Sub Heading */}

                            <h2 className="h4 text-white md:mt-5 mt-2 text-center font-light px-4">
                                {subHeading}
                                <span className="font-semibold text-yellow ">
                                    {' '}
                                    {subHeading_1}
                                </span>{' '}
                                {subHeading_2}
                            </h2>
                        </>
                    )}

                    {/* Nav Hash Link */}

                    {navhashLink ? (
                        <NavHashLink
                            to={`${navhashLink}`}
                            className="cta border-2 rounded-xl px-6 py-3 sm:text-xl text-sm mt-10 z-10"
                        >
                            Open opportunities
                        </NavHashLink>
                    ) : (
                        ''
                    )}

                    {scrollArrow ? (
                        <div className="scroll-arrow xl:w-16 lg:w-14 w-10 cursor-pointer">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                overflow="visible"
                                viewBox="0 0 85 85"
                            >
                                <circle
                                    cx="43.5"
                                    cy="43.5"
                                    r="40"
                                    stroke="url(#paint0_linear_490_250)"
                                    strokeWidth="3"
                                ></circle>
                                <path
                                    className="arrow-animation"
                                    fill="url(#paint1_linear_490_250)"
                                    d="M43.5 6a1.5 1.5 0 00-3 0h3zm-2.56 47.06a1.5 1.5 0 002.12 0l9.547-9.545a1.5 1.5 0 10-2.122-2.122L42 49.88l-8.485-8.486a1.5 1.5 0 10-2.122 2.122l9.546 9.546zM40.5 6v46h3V6h-3z"
                                ></path>
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_490_250"
                                        x1="73.115"
                                        x2="73.115"
                                        y1="111.615"
                                        y2="30.965"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop
                                            offset="0.202"
                                            stopColor="#006369"
                                        ></stop>
                                        <stop
                                            offset="1"
                                            stopColor="#08D8E4"
                                            stopOpacity="0"
                                        ></stop>
                                    </linearGradient>
                                    <linearGradient
                                        id="paint1_linear_490_250"
                                        x1="45.5"
                                        x2="41.722"
                                        y1="-3.2"
                                        y2="-2.006"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop
                                            stopColor="#fff"
                                            stopOpacity="0"
                                        ></stop>
                                        <stop
                                            offset="0"
                                            stopColor="#fff"
                                        ></stop>
                                        <stop
                                            offset="0.969"
                                            stopColor="#8FFFE9"
                                        ></stop>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </>
    );
};

export default Banner;
