import React, { useEffect } from 'react';
import Banner from '../../modules/molecules/banner';
import Blogs from './blogs';
import Decarbonizing from './decarbonize';
import Investors from './investors';
import Joinus from './joinus';
import Oursuite from './oursuite';
import Portfolio from './portfolio';
import Projects from './projects';
import Stats from './stats/stats';
import thumbnail from '@thumbnailsAssets/partners.jpg';
import PortfolioDraft from './portfolio-draft';
import { ProjectCarousel } from '../../modules/molecules';
import { CDN_URL } from '../../config';
export default function HomeScreen() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section>
            <Banner
                heading="Empowering smallholder farmers to remove carbon from the atmosphere"
                subHeading="On a mission to sequester"
                subHeading_1="1 Billion Tonnes"
                subHeading_2={
                    <>
                        of CO<sub>2</sub>e on smallholder lands
                    </>
                }
                videoLink={CDN_URL + '/Home_page.mp4'}
                fullScreen={true}
                thumbnail={thumbnail}
            />
            <Decarbonizing />
            {/* <Oursuite /> */}
            <Portfolio />
            {/* <PortfolioDraft /> */}
            {/* <Projects /> */}
            {/* <Joinus /> */}
            {/* <Blogs /> */}
            <Stats />
            <Investors />
        </section>
    );
}
