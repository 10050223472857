import React, { useEffect, useState } from 'react';
import './header.css';
import { IoMenu } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import MobileModal from './mobileModal';
import varahaLogo from '@iconsAssets/varaha-logo.svg';

import ContactHubSpot from '../contact-hubspot';

const Navigation = [
    {
        link: 'about-us',
        text: 'About us',
    },
    {
        link: 'careers',
        text: 'Careers',
    },
    {
        link: 'ourProjects',
        text: 'Projects',
    },
    // {
    //     link: 'for-businesses',
    //     text: 'For Businesses',
    // },
    // {
    //     link: 'for-partners',
    //     text: 'For Partners',
    // },
    {
        link: 'our-tech',
        text: 'Our Tech',
    },
];

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    // const [isActive, setIsActive] = useState("home");

    const handleModal = () => {
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    const handleContactModal = () => {
        if (isModalOpen) {
            setIsModalOpen(false);
        } else {
            setIsModalOpen(true);
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', () => {
            const scrollY = window.scrollY;
            setIsScrolled(scrollY);
            if (scrollY > 10) {
                setIsScrolled(true);
            }
        });
    }, [isScrolled]);

    return (
        <>
            {/* <div className="announcement bg-cream text-green  ">
                <div className="container flex justify-center py-1">
                    <div className="flex gap-2 xl:gap-0 items-center justify-between xl:justify-stretch relative">
                        <p className="text-green">
                            Varaha secures <b>$8.7 Million</b> in new funding
                        </p>
                        <div className="xl:absolute xl:right-[-6rem]">
                            <NavLink className="link" to="/funding">
                                Learn more
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div> */}
            <header
                className={`${
                    isScrolled ? 'backdrop-blur' : 'transparent'
                } transition-colors duration-500 lg:overflow-hidden`}
            >
                <div className={`header-wrapper container`}>
                    <div className="logo">
                        <NavLink to="/">
                            <img src={varahaLogo} alt="varaha logo" />
                        </NavLink>
                    </div>
                    <ul>
                        <li>
                            <button
                                className={`button white`}
                                onClick={handleContactModal}
                            >
                                Get in touch
                            </button>
                        </li>
                        {Navigation.map((item, key) => (
                            <li key={key}>
                                <NavLink
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'active navLink'
                                            : 'navLink unactive'
                                    }
                                    to={item.link}
                                >
                                    {item.text}
                                </NavLink>
                            </li>
                        ))}
                    </ul>

                    <IoMenu className="burger-icon" onClick={handleModal} />
                    <MobileModal
                        className={`${isOpen ? 'showModal' : 'hideModal'} `}
                        onClick={handleModal}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                    />
                </div>
            </header>
            <ContactHubSpot
                show={isModalOpen}
                handleClose={handleContactModal}
                id="hubspot-form-2"
                // setIsModalOpen={setIsModalOpen}
            />
        </>
    );
}
