import React, { useEffect, useRef } from 'react';
import './service.css';
import { jobListing } from '../config/config';

const Job = ({ title, description, applicationUrl, subtitle, googleForm }) => {
    return (
        <div className="career-block">
            <div className="lg:my-2 flex-1">
                <h5 className="h4">
                    <a href={applicationUrl} rel="noreferrer" target="_blank">
                        {description}
                    </a>
                </h5>
                <p className="p my-2 color-teal">{subtitle}</p>
            </div>
            <div className="lg:my-4 flex-1">
                <div className="flex gap-6 items-center justify-end">
                    <a
                        href={applicationUrl}
                        className="link green"
                        target="_blank"
                    >
                        Job Details
                    </a>
                    <a
                        href={googleForm}
                        className="button green"
                        target="_blank"
                    >
                        Apply
                    </a>
                </div>
            </div>
        </div>
    );
};

function groupBy(array, key) {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
        );
        // console.log(result);
        return result;
    }, {});
}

const jobs = groupBy(jobListing, 'title');

const JobList = ({ jobs }) => {
    return jobs.map((e, index) => (
        <Job
            title={e.title}
            description={e.description}
            applicationUrl={e.applicationUrl}
            subtitle={e.subtitle}
            googleForm={e.googleForm}
            key={e.id}
        />
    ));
};

const Jobs = () => {
    const jobRef = useRef();
    useEffect(() => {
        const script = document.createElement('script');

        if (jobRef.current) {
            script.type = 'text/JavaScript';
            script.src = 'https://ats.zimyo.com/assets/js/jobwidget_new.js';
            script.async = true;
            jobRef.current.appendChild(script);
            script.onload = () => {
                window.USERID = '4742';
            };
        }

        return () => {
            document.getElementById('job-listing')?.removeChild(script);
        };
    }, []);

    return <div id="job-listing" ref={jobRef}></div>;
};

const Service = () => {
    return (
        <>
            <section className="bg-cream section-padding" id="joinus">
                <div className="container">
                    <h2 className="h1 color-primary fw-500">Join the team</h2>
                    <h5 className="h3 lg:w-[50%] w-64 2xl:w-[25%] my-3 font-medium">
                        Take the next step in your career as a Varahan
                    </h5>

                    <Jobs />
                </div>
            </section>
        </>
    );
};

export default Service;
