import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import './footer.css';
import footerImage from '../../../assets/img/footer-image.jpg';
import FundingFooter from '../../../assets/img/FundingImage.png';
import projects from '../../data/projects';
import ContactHubSpot from '../contact-hubspot';

const Navigation = [
    {
        link: 'about-us',
        text: 'About us',
    },
    {
        link: 'careers',
        text: 'Careers',
    },

    {
        link: 'our-tech',
        text: 'Our Tech',
    },
    {
        link: 'https://varaha-privacy-policy.s3.ap-south-1.amazonaws.com/index2.html',
        text: 'Terms of use',
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        link: 'disclaimer',
        text: 'Disclaimer',
    },
];

const TwitterxIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 72 72"
            viewBox="0 0 72 72"
            id="twitter-x"
            color="white"
            {...props}
        >
            <switch>
                <g>
                    <path
                        d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66
			h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                    ></path>
                </g>
            </switch>
        </svg>
    );
};
export default function Footer() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleContactModal = () => {
        if (isModalOpen) {
            setIsModalOpen(false);
        } else {
            setIsModalOpen(true);
        }
    };

    return (
        <>
            <section className="relative bg-teal text-white">
                <div className="container flex relative z-10 py-70 md:py-140 items-start md:items-end flex-col md:flex-row gap-8">
                    <div className="flex-1 md:pr-32 pr-0">
                        <h2 className="h2 pb-4">Join the journey</h2>
                        <p className="pb-4">
                            Work with Varaha on your decarbonization and
                            sustainability goals
                        </p>
                        <button
                            className={`button white`}
                            onClick={handleContactModal}
                        >
                            Get in touch
                        </button>
                    </div>
                    <div className="flex-1 hidden md:block">
                        <p className="text-left md:text-right">
                            {/* <NavLink
                                className="color-yellow"
                                to={`ourProjects/`}
                            >
                                Our Projects
                            </NavLink> */}
                        </p>
                    </div>
                </div>
                <div className="relative md:absolute md:w-1/2 inset-y-0 right-0 z-1">
                    <img
                        src={FundingFooter}
                        className="object-cover w-full h-full"
                        alt="Funding"
                    />
                </div>
            </section>
            <footer className="bg-primary-dark text-white main-footer">
                <section className="footer-middle md:pt-24 md:pb-32 pt-12 pb-24 overflow-hidden">
                    <div className="container flex flex-col gap-16 justify-between md:flex-row">
                        <div className="basis-3/12 order-1">
                            <div className="flex gap-0 flex-col md:justify-between h-full">
                                <div>
                                    <p className="p">
                                        CIN: U72900HR2022PTC124578
                                    </p>
                                    <p className="p">TEL: +91-8011303269</p>
                                </div>
                                <div>
                                    <p className="p">
                                        DATA GRIEVANCE OFFICER :
                                        <a
                                            href="mailto:sumana.kundu@varahaag.com"
                                            className=" hover:text-yellow"
                                        >
                                            {'Sumana Kundu'}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="basis-3/12 order-3">
                            <div className="flex justify-between gap-8 md:gap-16 md:flex-col">
                                {/* <div className="flex-1">
                                    <h5 className="p mb-1">
                                        CORPORATE ADDRESS
                                    </h5>
                                    <h6 className="p color-grey">
                                        1st Floor, Building No. 10, Poorvi Marg,
                                        <br />
                                        Sector 25, DLF Ph-II, Gurgaon, DLF
                                        Ph-II,
                                        <br />
                                        Haryana, India, 122008
                                    </h6>
                                </div> */}
                                <div className="flex-1">
                                    <h5 className="p mb-1">
                                        REGISTERED ADDRESS
                                    </h5>
                                    <h6 className="p color-grey">
                                        1st Floor, Building No. 10, Poorvi Marg,
                                        <br />
                                        Sector 25, DLF Ph-II, Gurgaon, DLF
                                        Ph-II,
                                        <br />
                                        Haryana, India, 122008
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="basis-4/12 order-2 md:order-5">
                            <div className="flex justify-between gap-8">
                                <div className="flex-1 md:flex-none">
                                    <h4 className="p pb-2">PROJECTS</h4>
                                    <ul className="">
                                        {projects.map((item, key) => (
                                            <li key={key}>
                                                <NavLink
                                                    className="color-grey color-grey hover:text-yellow"
                                                    to={
                                                        `ourProjects/` +
                                                        item.link
                                                    }
                                                >
                                                    {item.text}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="flex-1 md:flex-none">
                                    <h4 className="p pb-2">EXPLORE MORE</h4>
                                    <ul className="mb-6">
                                        {Navigation.map((item, key) => (
                                            <li key={key} className="">
                                                {item.target && item.rel ? (
                                                    <NavLink
                                                        className={({
                                                            isActive,
                                                        }) => {
                                                            return isActive
                                                                ? 'color-grey hover:text-yellow active navLink'
                                                                : 'navLink color-grey hover:text-yellow';
                                                        }}
                                                        to={item.link}
                                                        rel={item.rel}
                                                        target={item.target}
                                                    >
                                                        {item.text}
                                                    </NavLink>
                                                ) : (
                                                    <NavLink
                                                        className={({
                                                            isActive,
                                                        }) =>
                                                            isActive
                                                                ? 'color-grey hover:text-yellow active navLink'
                                                                : 'navLink color-grey hover:text-yellow'
                                                        }
                                                        to={item.link}
                                                    >
                                                        {item.text}
                                                    </NavLink>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                    <a
                                        href="mailto:careers@varahaag.com"
                                        className="p underline block mb-2 hover:text-yellow"
                                    >
                                        careers@varahaag.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="footer-bottom">
                    <div className="container flex flex-col sm:flex-row-reverse py-8 justify-between items-center gap-4 items-center">
                        <div className="flex-1 w-full">
                            <div className="flex gap-4 justify-start sm:justify-end">
                                {/* <a
                                    href="https://www.facebook.com/profile.php?id=100086691786188&sk=about"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaGithub className="text-2xl hover:text-yellow  color-grey" />
                                </a> */}
                                <a
                                    href="https://instagram.com/varaha.earth?igshid=YmMyMTA2M2Y="
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaInstagram className="text-2xl hover:text-yellow  color-grey" />
                                </a>

                                <a
                                    href="https://www.linkedin.com/company/varaha-carbontech/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaLinkedinIn className="text-2xl hover:text-yellow  color-grey" />
                                </a>
                                {/* <a
                                    href="https://www.linkedin.com/company/varaha-carbontech/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaDiscord className="text-2xl hover:text-yellow color-grey" />
                                </a> */}
                            </div>
                            {/*
                        <div className="flex gap-x-8">
                            <a target="_blank" href="https://varaha-privacy-policy.s3.ap-south-1.amazonaws.com/index2.html" rel="noreferrer" className='p'>
                                Terms of use
                            </a>
                            <NavLink to="/disclaimer" className='p'>
                                Disclaimer
                            </NavLink>
                        </div>
                        */}
                        </div>
                        <div className="flex-1">
                            <p className="p text-gray-400">
                                &copy; 2022 Varaha ClimateAg Private Limited.
                                All rights reserved.
                            </p>
                        </div>
                    </div>
                </section>

                <ContactHubSpot
                    show={isModalOpen}
                    handleClose={handleContactModal}
                    id="hubspot-form-1"
                    // setIsModalOpen={setIsModalOpen}
                />
            </footer>
        </>
    );
}
