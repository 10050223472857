import React, { useEffect } from 'react';
import { BannerSecondary } from '../../modules/molecules';
import Benefits from './howItWork';
import Projects from './projects';
import thumbnail from '@thumbnailsAssets/ourtech.jpg';
const ForBusiness = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <BannerSecondary
                heading="Digital innovation that enables the quantification of carbon removal at scale"
                subHeading="Reverse your GHG emissions with high-quality carbon credits"
                videoLink="https://dfrlze9c0ze95.cloudfront.net/our_tech.mp4"
                thumbnail={thumbnail}
            />

            <Benefits />

            {/* <Projects /> */}
        </>
    );
};
export default ForBusiness;
