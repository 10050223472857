import React from 'react';
import './timeline.css';
import first from '@aboutAssets/our-story/image(1).jpg';
import second from '@aboutAssets/our-story/image(2).jpg';
import third from '@aboutAssets/our-story/image(3).jpg';
import fourth from '@aboutAssets/our-story/image(4).jpg';
import fifth from '@aboutAssets/our-story/image(5).jpg';
import sixth from '@aboutAssets/our-story/image(6).jpg';
import seventh from '@aboutAssets/our-story/image(7).jpg';
import eight from '@aboutAssets/our-story/image(8).jpg';

export default function Oursuite({ defaultTab = 0 }) {
    const [activeTab, setActiveTab] = React.useState(defaultTab);

    React.useEffect(() => {
        const tabs = document.querySelectorAll('[role="tab"]');
        const arrowKeyHandler = (event) => {
            let nextTab;
            switch (event.key) {
                case 'ArrowRight':
                    nextTab = activeTab >= tabs.length - 1 ? 0 : activeTab + 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                case 'ArrowLeft':
                    nextTab = activeTab <= 0 ? tabs.length - 1 : activeTab - 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                default: {
                }
            }
        };
        document.addEventListener('keyup', arrowKeyHandler);
        return () => {
            document.removeEventListener('keyup', arrowKeyHandler);
        };
    }, [activeTab]);

    const tabs = [
        {
            title: '2021',
            heading: 'Preliminary groundwork laid',
            li1: 'Extensive research on reasons, mechanisms, and incentives that can motivate communities to implement climate-positive practices.',
            li2: 'Built a robust understanding of the climate/carbon credit markets and the existing issues that pertain related to them.',
            li3: 'Brainstormed and worked around a suite of processes and tools that would help us execute the implementation of carbon-removing activities.',
            li4: 'Came to terms with the role technology could play in protecting and restoring the tiny space rock called Earth.',
            src: first,
        },
        {
            title: 'Feb 2022 ',
            heading: 'Varaha incorporated',
            src: second,
        },
        {
            title: 'March 2022',
            heading: 'Full-fledged Operations began',
            li1: 'Operations began in several Indian states courtesy of a kickass team with professional experience of working across 5 continents, encompassing Entrepreneurs/Intrapreneurs, Remote Sensing Scientists, Climate Scientists, Data Scientists, Product Developers, Seasoned C-Suite Business Development Execs.',
            li4: 'Major sourcing done from-',
            li_1: 'IIM Ahmedabad',
            li_2: 'IIT Delhi',
            li_3: 'The Pennsylvania State University',
            li_4: 'Indian Institute of Sciences',
            li_5: 'Indian Institute of Remote Sensing',
            li_6: 'University of Jyväskylä, Finland',
            li_7: 'IIT Kharagpur',
            src: third,
        },
        {
            title: 'May 2022 ',
            heading: 'Raised a Pre-Seed Funding Round ',
            src: fourth,
        },
        {
            title: 'June 2022',
            heading: 'Signed MoUs and joined forces with several FPOs',
            heading2:
                'Signed an MoU with Maharana Pratap Horticulture University, Karnal, Government of Haryana',
            src: fifth,
        },
        {
            title: 'July 2022',
            heading: 'Product Proof-of-Concept finalized',
            src: sixth,
        },
        {
            title: 'August 2022',
            heading: 'Projects listed on Verra Standard',
            src: seventh,
        },
        {
            title: 'Sep 2022',
            heading:
                'Parallel expansion began in greater South Asia starting from Nepal',
            src: eight,
        },
    ];

    return (
        <section className="timeline-wrapper blade-padding-sm  pb-32">
            <div className="flex flex-col gap-4">
                <h2 className="h1 text-gradient font-medium container">
                    Our Story
                </h2>
                <p className="text-white text-gradient h4 container">
                    Varaha was founded in 2022 with the conviction that
                    smallholders in Asia could be a critical point of leverage
                    in removing carbon from the atmosphere at a planetary scale.
                    Since then, we have become a diverse team of scientists,
                    technologists, and agricultural experts all working toward
                    scaling up nature-based solutions to the climate crisis. We
                    are proud to partner every day with farmers, community
                    organizations, and academic institutions in a shared
                    journey.
                </p>
            </div>
        </section>
    );
}
