import React from 'react';
import projectThumbnailOne from '@homeAssets/project-card-one.jpg';
import projectThumbnailTwo from '@homeAssets/project-card-two.jpg';
import { ProjectCards } from '../../../modules/atoms';
import { ProjectCarousel } from '../../../modules/molecules';

const project1 = [
    {
        lh: 'Tenure',
        li: ' 2019 - 2038',
    },
    {
        lh: 'Area under Focus',
        li: 'Indo-Gangetic Plains',
    },
    {
        lh: 'Area of Coverage',
        li: '2 Million Hectares by 2027',
    },
    {
        lh: 'Emission Reduction',
        li: '7.0 Million Tonnes CO\u2082 eq./annum',
    },
];

const project2 = [
    {
        lh: 'Tenure',
        li: '  2022 - 2028',
    },
    {
        lh: 'Area under Focus',
        li: 'Haryana',
    },
    {
        lh: 'Area of Coverage',
        li: '57,000 Hectares',
    },
    {
        lh: 'Emission Reduction',
        li: '60,000 Tonnes CO\u2082 eq./annum',
    },
];

const Projects = () => {
    return (
        <section className="nature-section">
            {/* <div className="bg-primarylight ">
                <div className="container md:pt-52 pt-20">
                    <h2
                        data-aos="fade-up"
                        className="text-primary leading-snug mr-4 h2 max-w-3xl font-semibold drop-shadow-2xl "
                    >
                        Let’s accelerate climate action together
                    </h2>
                    <h4
                        data-aos="fade-up"
                        className="text-[#7D7D7D] leading-snug mt-4 h4 max-w-3xl font-medium drop-shadow-2xl "
                    >
                        Glance through our suite of technologically-verified
                        offsetting projects following international standards
                        for carbon credits & transparency
                    </h4>
                </div>
            </div> */}

            <div className="bg-primarylight pb-40 pt-28">
                <ProjectCarousel darkArrows={true} />
            </div>
        </section>
    );
};

export default Projects;
