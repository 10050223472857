import React from 'react';
import './joinus.css';
import joinUsBg from '@aboutAssets/joinusBg.svg';
import Button from '@atoms/button';
export default function Joinus() {
    return (
        <section className="">
            <section className="bg-primarylight blade-padding-lg">
                <div className="w-11/12 mx-auto text-center grid place-content-center place-items-center gap-4 md:gap-6 lg:gap-8">
                    <span className=" text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl fw-700 leading-tight text-primary ">
                        Join the team!
                    </span>
                    <span className="text-base md:text-lg xl:text-xl fw-500 text-darkGray">
                        We're just getting started. We are looking for
                        passionate people with a wide range of experiences, all
                        ready to apply their talents to combatting climate
                        change as Varahans.
                    </span>
                    <Button
                        reverse
                        title="Apply Here"
                        className="h6 my-4"
                        to="/careers"
                        id="joinus"
                    />
                </div>
                <div className="mt-10 md:mt-14 lg:mt-20 min-h-[100px] select-none pointer-events-none">
                    <img
                        src={joinUsBg}
                        alt=""
                        className="w-full min-h-[100px] h-full object-center object-cover"
                    />
                </div>
            </section>
        </section>
    );
}
