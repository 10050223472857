import React, { useEffect } from 'react';

export default function Disclaimer() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="h-auto bg-primary flex flex-col justify-center items-center py-20">
                <div>
                    <h1 className="h2 font-extralight text-white">
                        Disclaimer{' '}
                    </h1>
                </div>
                <div className="border-teal-800 border-2 rounded-2xl">
                    <ul className="marker:text-white list-outside list-disc p-10">
                        <li className="font-extralight text-white h6 leading-loose">
                            Carbon credits may be issued through Verified Carbon
                            Standard project 4046 for the <br />
                            greenhouse gas emission reductions or removals
                            associated with the afforestation industry <br />
                            in Nepal.
                        </li>
                        <li className="font-extralight text-white h6 leading-loose">
                            Carbon credits may be issued through Verified Carbon
                            Standard project 4043 for the <br /> greenhouse gas
                            emission reductions or removals associated with the
                            afforestation
                            <br /> industry in the state of Andhra Pradesh.
                        </li>
                        <li className="font-extralight text-white h6 leading-loose">
                            VCUs may be issued for the greenhouse gas emission
                            reductions and removals associated
                            <br /> with rice industry in Madhya Pradesh, India.
                        </li>
                        <li className="font-extralight text-white h6 leading-loose">
                            Carbon credits may be issued through Verified Carbon
                            Standard project (4456) for the <br></br>greenhouse
                            gas emission reductions or removals associated with
                            the horticultural industry <br></br>in Bangladesh
                        </li>
                        <li className="font-extralight text-white h6 leading-loose">
                            Carbon credits may be issued through Verified Carbon
                            Standard project 3346 for the green
                            <br />
                            house gas emission reductions or removals associated
                            with the rice, wheat, maize, cotton,
                            <br /> mustard, pulses, and sugarcane industries in
                            the Indo-Gangetic Plains of India.
                        </li>
                        <li className="font-extralight text-white h6 leading-loose">
                            VCUs may be issued for the greenhouse gas emission
                            reductions and removals associated
                            <br /> with rice industry in Haryana, India.
                        </li>
                        <li className="font-extralight text-white h6 leading-loose">
                            VCUs may be issued for the greenhouse gas emission
                            reductions and removals associated <br />
                            with rice, wheat, cotton, maize, millets, legumes
                            and sugarcane industry in Himachal, <br />
                            Rajasthan, Jharkhand, Chhattisgarh, Assam, Madhya
                            Pradesh,Maharashtra, Andhra Pradesh, <br />
                            Telangana, Karnataka and Tamil Nadu, India
                        </li>
                        <li className="font-extralight text-white h6 leading-loose">
                            VCUs may be issued for the greenhouse gas emission
                            reductions and removals associated <br />
                            with rice, wheat, cotton, maize, legumes and
                            sugarcane industry in Gujarat India
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
