import React from 'react';
import './define.css';
import pattern from '../../../assets/careerpage/pattern-green-repeat 1.svg';

const Define = () => {
    return (
        <>
            <section className="define_container">
                <div className=" ">
                    <h2 className="h2 text-center color-primary font-medium">
                        What defines us?
                    </h2>
                    <div className="define_cards">
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Inclusive</h5>
                            <p>
                                We are a diverse group of scientists, engineers,
                                carbon finance experts, former founders, and
                                more
                            </p>
                        </div>
                        {/* <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Growth-Oriented</h5>
                            <p>We move fast and learn fast</p>
                        </div> */}
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Growth-Oriented</h5>
                            <p>
                                We move fast and learn fast&nbsp; &nbsp;
                                &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                                &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                                &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                                &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                                &nbsp;&nbsp;&nbsp;
                            </p>
                        </div>
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Biased to action</h5>
                            <p>
                                We operate with the urgency that is required to
                                combat the climate crisis
                            </p>
                        </div>
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Science first</h5>
                            <p>
                                We are committed to following and advancing the
                                frontiers of scientific knowledge on
                                nature-based carbon removal pathways
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Define;
