import React from 'react';
import { CardFlexPrime } from '../../../modules/molecules';
import TechMRV from '../MRV';
import datacollectionAgro from '../../../assets/ourtechPage/agro/datacollection.svg';
import datacollectionRegen from '../../../assets/ourtechPage/regen/datacollection.png';
import calibratedAgro from '../../../assets/ourtechPage/agro/calibrated.svg';
import calibratedRegen from '../../../assets/ourtechPage/regen/calibrated.png';
import groundAgro from '../../../assets/ourtechPage/agro/grounddata.svg';
import groundRegen from '../../../assets/ourtechPage/regen/grounddata.png';
import outputAgro from '../../../assets/ourtechPage/agro/output.svg';
import outputRegen from '../../../assets/ourtechPage/regen/output.png';
import { CDN_URL } from '../../../config';
export default function HowItWork() {
    const techMRVAgro = [
        datacollectionAgro,
        groundAgro,
        calibratedAgro,
        outputAgro,
    ];
    const techMRVRegn = [
        datacollectionRegen,
        groundRegen,
        calibratedRegen,
        outputRegen,
    ];
    return (
        <>
            <TechMRV
                title="Afforestation"
                subtitle=""
                subtitle_1="A combination of remote sensing and LiDAR-trained AI-ML satellite models estimates the biomass accurately at scale"
                data={techMRVAgro}
                videoSrc="https://res.cloudinary.com/dqa5nuwvf/video/upload/v1697094527/varaha-lp2/tch/k6ni3zelbclufxcvtdvp.mp4"
            />
            <TechMRV
                title="Regenerative Agriculture"
                subtitle=""
                subtitle_1="A combination of remote sensing and biogeochemical models estimates GHG emissions accurately at scale"
                data={techMRVRegn}
                videoSrc={CDN_URL + '/agrotechmrv.mp4'}
            />
        </>
    );
}
