import React, { useState } from 'react';

import iconAgri from '@homeAssets/portfolio/Agriculture.svg';
import iconAgro from '@homeAssets/portfolio/Agroforestry.svg';
import iconForest from '@homeAssets/portfolio/ForestConservation.svg';
import iconBioChar from '@homeAssets/portfolio/ERW.svg';

import { CDN_URL } from '../../../config';

import './index.css';
const Card = ({ title, desc, iconSrc, idx, onChangeHandler }) => {
    return (
        <div className="flex px-4">
            <div>
                <label className="data-card cursor-pointer mt-10">
                    <input
                        type="radio"
                        defaultChecked={idx === 0}
                        autoFocus={idx === 0}
                        name="portfolio-option"
                        onChange={() => {
                            onChangeHandler(idx);
                        }}
                    />

                    <div className="wrapper border-2 text-[#6e6e6e] ">
                        <img src={iconSrc} className="tab-icons" alt={title} />
                        <div className="h5 py-2 fw-500 ">{title} </div>
                        <div className="desc">
                            <span className="h6 fw-400">{desc} </span>
                        </div>
                    </div>
                </label>
            </div>
        </div>
    );
};
export default function Portfolio() {
    const [activeIndex, setActiveIndex] = useState(0);
    const cardData = [
        {
            title: 'Afforestation',
            icon: iconAgro,

            img: CDN_URL + '/Home' + '/portfolio/' + 'agro.png',

            desc: 'Planting native tree species that sequester carbon as they grow and produce income-generating crops for smallholders, like fruits and nuts.',
        },
        {
            title: ' Regenerative Agriculture',
            icon: iconAgri,
            img: CDN_URL + '/Home' + '/portfolio/' + 'regenag.png',
            desc: 'Helping smallholder farmers adopt regenerative agricultural practices that build soil organic carbon, improve yields, and increase climate resilience.',
        },
        {
            title: 'Biochar',
            icon: iconForest,
            img: CDN_URL + '/Home' + '/portfolio/' + 'biochar.png',
            desc: 'Using an invasive woody species as a feedstock for high-temperature pyrolysis into stable biochar, which is then applied as a soil amendment to enrich fertility.',
        },
        {
            title: 'Enhanced Rock Weathering',
            icon: iconBioChar,
            img: CDN_URL + '/Home' + '/portfolio/' + 'erw_detail.png ',
            desc: 'Applying fine basalt rock to acidic agricultural fields to balance pH and capture carbon in the form of carbonate minerals.',
        },
    ];

    return (
        <div className="bg-cream portfolio-blade-wrapper">
            <div className="blade-padding-sm">
                <h2
                    className="h2 text-center text-primary font-medium xl:max-w-screen-lg lg:max-w-screen-sm  max-w-screen-sm mx-auto leading-tight px-10"
                    data-aos="fade-up"
                >
                    Catalyzing climate action through four nature-based pathways
                </h2>

                <div
                    className=" container flex sm:flex-row flex-col-reverse  items-center mt-20 justify-between"
                    data-aos="fade-up"
                >
                    <div className="sm:w-6/12 w-full relative sm:mt-0 mt-10">
                        <div className="grid gap-7">
                            {cardData.map((elem, index) => {
                                const {
                                    title = '',
                                    desc = '',
                                    icon = '',
                                } = elem;
                                return (
                                    <Card
                                        key={index + title}
                                        idx={index}
                                        iconSrc={icon}
                                        title={title}
                                        desc={desc}
                                        onChangeHandler={(index) =>
                                            setActiveIndex(index)
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <div
                        data-aos="fade-up"
                        role="tabpanel-envision"
                        className="sm:mb-[6rem]"
                    >
                        {cardData.map((elem, index) => {
                            if (activeIndex === index)
                                return (
                                    <img
                                        className={`rounded-xl`}
                                        src={elem.img}
                                        alt=""
                                        key={index + '.png'}
                                    />
                                );
                            else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
