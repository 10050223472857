import React from 'react';
import './internship.css';
import program from '../../../assets/careerpage/intership.jpg';
import { Button } from '../../../modules/atoms';

const Internship = () => {
    return (
        <div className="blog-content relative ">
            <div className="h-fit relative shadow-lg hover:shadow-2xl transition-shadow duration-500">
                <img
                    className="object-cover 2xl:h-screen"
                    src={program}
                    alt="internship"
                />

                <div className="lg:absolute relative bottom-[1px] right-0 lg:w-[53%] w-[100%] bg-cream px-10 2xl:py-24 lg:py-12 pb-9">
                    <h2 className="h2 max-w-[90%] font-medium 2xl:text-5xl ">
                        Varaha Internship Program
                    </h2>
                    <h4 className=" my-5   2xl:text-2xl ">
                        Developing the next generation of climate and technology
                        leaders
                    </h4>
                    <Button
                        reverse
                        title="Apply Here"
                        href="https://forms.gle/J6MsCUsnkXgCrLXRA"
                        className="h6 mt-4 "
                    />
                </div>
            </div>
        </div>
    );
};

export default Internship;
