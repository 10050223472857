import { CDN_URL } from '../../../config';
//contents for project selection page
export const totalProjects = 7;
export const projectList = [
    {
        number: '01',
        id: 'regen_01',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Regenerative Agriculture
                    <br />
                    Indo-Gangetic Plains,
                    <br />
                    India
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Regenerative Agriculture
                <br />
                Indo-Gangetic Plains,
                <br />
                India
            </h3>
        ),
        subTitle:
            'Incorporation of regenerative agriculture practices on degraded arable land used for rice, wheat, maize, cotton, and sugarcane cultivation; VM0042 methodology.',
        imageUrl: CDN_URL + '/ourProjects/project_01/01.png',
        secImageUrl: CDN_URL + '/ourProjects/project_01/01_secondary.png',
    },

    {
        number: '02',
        id: 'afoo_nep_02',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Afforestation, Nepal
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">Afforestation, Nepal</h3>
        ),
        subTitle:
            'Planting and maintenance of native trees, including fruit and nut trees on barren non-forest land.',
        imageUrl: CDN_URL + '/ourProjects/project_02/02.png',
        secImageUrl: CDN_URL + '/ourProjects/project_02/02_secondary.png',
    },

    {
        number: '03',
        id: 'afoo_bangla_03',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Afforestation, Bangladesh
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Afforestation, Bangladesh
            </h3>
        ),
        subTitle:
            'Planting and maintenance of native fruit trees on fallow land with Syngenta Foundation; AR-ACM0003 methodology.',
        imageUrl: CDN_URL + '/ourProjects/project_03/03.png',
        secImageUrl: CDN_URL + '/ourProjects/project_03/03_secondary.png',
    },
    {
        number: '04',
        id: 'afoo_bio_04',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Durable Carbon Removal <br /> -Artisanal Biochar, India
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Biochar & Restoration
                <br />
                Banni Grasslands, <br />
                India
            </h3>
        ),
        subTitle:
            'Artisanal biochar is made from the invasive Prosopis juliflora, which harms local ecosystems and livelihoods. Using KonTiki kilns for low-emission production, the biochar is then used to enhance soil and restore grasslands.',
        imageUrl: CDN_URL + '/ourProjects/project_04/04.png',
        secImageUrl: CDN_URL + '/ourProjects/project_04/04_secondary.png',
    },
    {
        number: '05',
        id: 'afoo_regen_05',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Regenerative Agriculture
                    <br />
                    Gujarat, India
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Regenerative Agriculture
                <br />
                Gujarat, India
            </h3>
        ),
        subTitle:
            'Carbon Farming Program, launched in 2020, emphasizes regenerative agriculture, including drip irrigation and natural/organic farming techniques, targeting 312,000 hectares by 2040. Generating significant removal credits boosts soil quality, reduces erosion, and enhances water quality while increasing climate resilience for smallholder farmers.',
        imageUrl: CDN_URL + '/ourProjects/project_05/05.png',
        secImageUrl: CDN_URL + '/ourProjects/project_05/05_secondary.png',
    },
    {
        number: '06',
        id: 'afoo_bio_06',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Afforestation, Southern India
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Afforestation, Southern,India
            </h3>
        ),
        subTitle:
            'The Southern India ARR project promotes native tree cultivation and sustainable agriculture across Andhra Pradesh, Telangana, Tamil Nadu, Kerala, and Karnataka. Through a holistic approach, it aims to alleviate tribal poverty, enhance carbon sequestration, and boost farmer incomes, with a projected expansion to 75,000 hectares by 2029.',

        imageUrl: CDN_URL + '/ourProjects/project_06/06.png',
        secImageUrl: CDN_URL + '/ourProjects/project_06/06_secondary.png',
    },
    {
        number: '07',
        id: 'ewr_07',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Enhanced Rock Weathering,
                    <br />
                    Madhya Pradesh
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Enhanced Rock Weathering,
                <br />
                Madhya Pradesh
            </h3>
        ),
        subTitle:
            'A carbon sequestration project using Enhanced Rock Weathering (ERW) on farms to capture CO2, converting it into stable carbonates and bi-carbonates through a natural weathering process. This method improves soil health, increases crop yields, and contributes to climate change mitigation.',
        imageUrl: CDN_URL + '/ourProjects/project_07/07.png',
        secImageUrl: CDN_URL + '/ourProjects/project_07/07_secondary.png',
    },
    // {
    //     number: '08',
    //     id: 'pyro_08',
    //     titleComponent: (
    //         <>
    //             <h1 className="text-white h1 leading-tight">
    //                 Puro Industrial Gasifier,
    //                 <br /> Telangana, India
    //             </h1>
    //         </>
    //     ),
    //     title: (
    //         <h3 className="text-white h5 font-medium">
    //             Puro Industrial Gasifier,
    //             <br /> Telangana, India
    //         </h3>
    //     ),
    //     subTitle:
    //         'A high-tech maize seed processing facility converts corn cob waste into syngas and biochar, removing over 1,000 tonnes of CO₂ eq. annually by producing and distributing free biochar to nearby farmers to improve soil health.',

    //     imageUrl: CDN_URL + '/ourProjects/project_08/08.png',
    //     secImageUrl: CDN_URL + '/ourProjects/project_08/08_secondary.png',
    // },
];
/***************************************************************************** */
// content mobile view of project selection page
export const mobileProjectList = [
    {
        number: '01',
        id: 'regen_01',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Regenerative Agriculture
                    <br />
                    Indo-Gangetic Plains,
                    <br />
                    India
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Regenerative Agriculture
                <br />
                Indo-Gangetic Plains,
                <br />
                India
            </h3>
        ),
        subTitle:
            'Incorporation of regenerative agriculture practices on degraded arable land used for rice, wheat, maize, cotton, and sugarcane cultivation; VM0042 methodology.',
        imageUrl: CDN_URL + '/ourProjects/project_01/01.png',
    },

    {
        number: '02',
        id: 'afoo_nep_02',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Afforestation, Nepal
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">Afforestation, Nepal</h3>
        ),
        subTitle:
            'Planting and maintenance of native trees, including fruit and nut trees on barren non-forest land.',
        imageUrl: CDN_URL + '/ourProjects/project_02/02.png',
    },

    {
        number: '03',
        id: 'afoo_bangla_03',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Afforestation, Bangladesh
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Afforestation, Bangladesh
            </h3>
        ),
        subTitle:
            'Planting and maintenance of native fruit trees on fallow land with Syngenta Foundation; AR-ACM0003 methodology.',
        imageUrl: CDN_URL + '/ourProjects/project_03/03.png',
    },
    {
        number: '04',
        id: 'afoo_bio_04',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Durable Carbon Removal <br /> -Artisanal Biochar, India
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Biochar & Restoration
                <br />
                Banni Grasslands, <br />
                India
            </h3>
        ),
        subTitle:
            'Artisanal biochar is made from the invasive Prosopis juliflora, which harms local ecosystems and livelihoods. Using KonTiki kilns for low-emission production, the biochar is then used to enhance soil and restore grasslands.',
        imageUrl: CDN_URL + '/ourProjects/project_04/04.png',
    },
    {
        number: '05',
        id: 'afoo_regen_05',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Regenerative Agriculture
                    <br />
                    Gujarat, India
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                High Tech Biochar Production
                <br />
                & Soil Application
                <br />
                Gujarat, India
            </h3>
        ),
        subTitle:
            'Carbon Farming Program, launched in 2020, emphasizes regenerative agriculture, including drip irrigation and natural/organic farming techniques, targeting 312,000 hectares by 2040. Generating significant removal credits boosts soil quality, reduces erosion, and enhances water quality while increasing climate resilience for smallholder farmers.',
        imageUrl: CDN_URL + '/ourProjects/project_05/05.png',
    },
    {
        number: '06',
        id: 'afoo_bio_06',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Afforestation, Southern India
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Afforestation, Southern,India
            </h3>
        ),
        subTitle:
            'The Southern India ARR project promotes native tree cultivation and sustainable agriculture across Andhra Pradesh, Telangana, Tamil Nadu, Kerala, and Karnataka. Through a holistic approach, it aims to alleviate tribal poverty, enhance carbon sequestration, and boost farmer incomes, with a projected expansion to 75,000 hectares by 2029.',
        imageUrl: CDN_URL + '/ourProjects/project_06/06.png',
    },
    {
        number: '07',
        id: 'ewr_07',
        titleComponent: (
            <>
                <h1 className="text-white h1 leading-tight">
                    Enhanced Rock Weathering,
                    <br /> Jharkhand and Chhattisgarh
                </h1>
            </>
        ),
        title: (
            <h3 className="text-white h5 font-medium">
                Enhanced Rock Weathering,
                <br /> Jharkhand and Chhattisgarh
            </h3>
        ),
        subTitle:
            'A carbon sequestration project using Enhanced Rock Weathering (ERW) on farms to capture CO2, converting it into stable carbonates and bi-carbonates through a natural weathering process. This method improves soil health, increases crop yields, and contributes to climate change mitigation.',

        imageUrl: CDN_URL + '/ourProjects/project_07/07.png',
    },
    // {
    //     number: '08',
    //     id: 'pyro_08',
    //     titleComponent: (
    //         <>
    //             <h1 className="text-white h1 leading-tight">
    //                 Puro Industrial Gasifier,
    //                 <br /> Telangana, India
    //             </h1>
    //         </>
    //     ),
    //     title: (
    //         <h3 className="text-white h5 font-medium">
    //             Puro Industrial Gasifier,
    //             <br /> Telangana, India
    //         </h3>
    //     ),
    //     subTitle:
    //         'A high-tech maize seed processing facility converts corn cob waste into syngas and biochar, removing over 1,000 tonnes of CO₂ eq. annually by producing and distributing free biochar to nearby farmers to improve soil health.',

    //     imageUrl: CDN_URL + '/ourProjects/project_08/banner.png',
    // },
];
/***************************************************************************** */
// 1.content for Indo-Gangetic Plains

const ourProjectsPath = '/ourProjects';
const indo_sdgPath = ourProjectsPath + '/project_01/impact';

export const indoSdgContent = [
    {
        title: 'No Poverty',
        subtitle:
            'The discontinuation of the practice of crop residue burning has prevented a GDP loss of approximately $22 million during the current monitoring period for the project interventions.',
        backgroundImage: CDN_URL + indo_sdgPath + '/no_poverty.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/no_poverty.png',
    },
    {
        title: 'Zero Hunger',
        subtitle:
            'From 2019 to 2022, participating farmers witnessed a 15% increase in their average income, enabling them to afford an adequate food supply. Furthermore, farmers in Punjab and Haryana will experience a further increase in income as they will receive approximately 51-70% of the revenue generated from the sale of carbon credits.',
        backgroundImage: CDN_URL + indo_sdgPath + '/no_hunger.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/hunger.png',
    },
    {
        title: 'Good Health & Well-being',
        subtitle:
            'In the current monitoring period, around 127,945 hectares of farmland in Punjab and Haryana have adopted residue incorporation practices, effectively reducing ambient air pollution and lowering the mortality rate associated with it. This intervention has saved approximately 166 lives during the current monitoring period.',
        backgroundImage: CDN_URL + indo_sdgPath + '/health.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/health.png',
    },
    {
        title: 'Quality Education',
        subtitle:
            'We have provided vocational training to youth and adults in Punjab and Haryana, focusing on the Carbon Markets Ecosystem. These trained individuals play a crucial role in reaching out to farmers at the grassroots level, educating them about the importance of adopting regenerative agriculture and contributing to climate change mitigation.',
        backgroundImage: CDN_URL + indo_sdgPath + '/education.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/education.png',
    },
    {
        title: 'Gender Equality',
        subtitle:
            'Varaha is led by our female Chief Operating Officer (COO). We also have two female employees in managerial positions working on the project. Additionally, we have hired and trained three women as enumerators and surveyors on the ground.',
        backgroundImage: CDN_URL + indo_sdgPath + '/gender.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/gender.png',
    },
    {
        title: 'Clean Water & Sanitation',
        subtitle:
            'In the current monitoring period, approximately 6,857 hectares of farmland in the project area have adopted Direct Seeded Rice (DSR) for paddy cultivation, resulting in improved water-use efficiency.',
        backgroundImage: CDN_URL + indo_sdgPath + '/cleanwater.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/cleanwater.png',
    },
    {
        title: 'Decent Work & Economic Growth',
        subtitle:
            'We are providing employment and decent work opportunities for both men and women in Punjab and Haryana. Equal pay is ensured for work of equal value. Currently, we have employed approximately 34 individuals on the ground.',
        backgroundImage: CDN_URL + indo_sdgPath + '/economy.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/economy.png',
    },
    {
        title: 'Reduced Inequalities',
        subtitle:
            'By offering employment opportunities to youth, men, and women from the farming community, their household incomes have significantly increased during the monitoring period.',
        backgroundImage: CDN_URL + indo_sdgPath + '/reduced_inequality.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/inequalities.png',
    },
    {
        title: 'Sustainable Cities & Communities',
        subtitle:
            'During the current monitoring period, around 127945 hectares of farmland have incorporated residue into their fields, leading to a reduction of 15,055 tons of fine particulate matter emissions in nearby cities.',
        backgroundImage: CDN_URL + indo_sdgPath + '/sustainable_cities.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/sustainable.png',
    },
    {
        title: 'Responsible Consumption & Production',
        subtitle:
            'We promote regenerative agricultural practices such as residue incorporation and Direct Seeded Rice (DSR) cultivation among farmers in Punjab and Haryana. These practices ensure the efficient and sustainable use of agricultural land.',
        backgroundImage:
            CDN_URL + indo_sdgPath + '/responsible_Consumption.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/responsible.png',
    },
    {
        title: 'Climate Action',
        subtitle:
            'The project activities result in an average reduction and removal of 277,654 tonnes of CO₂ equivalent per year during the monitoring period.',
        backgroundImage: CDN_URL + indo_sdgPath + '/climate.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/climate.png',
    },
    {
        title: 'Partnership for the Goals',
        subtitle:
            'We have secured funding of $4 million from international investors for the project, which has been utilized in its development in Punjab and Haryana.',
        backgroundImage: CDN_URL + indo_sdgPath + '/partner.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/partnerships.png',
    },
];
const practices_path = ourProjectsPath + '/project_01/practices';
export const practicesTabs = [
    {
        title: 'Direct Seeding of Rice',
        before: {
            thumbnail: CDN_URL + practices_path + '/dsr_before.png',
            videoLink: CDN_URL + practices_path + '/dsr_before.mp4',
            title: 'Transplantation of rice',
        },
        after: {
            thumbnail: CDN_URL + practices_path + '/dsr_after.png',
            videoLink: CDN_URL + practices_path + '/dsr_after.mp4',
            title: 'Direct Seeding of rice',
        },
    },
    {
        title: 'Crop Residue Management',
        before: {
            thumbnail: CDN_URL + practices_path + '/crop_after.png',
            videoLink: CDN_URL + practices_path + '/crop_after.mp4',
            title: 'Crop Residue Burning',
        },
        after: {
            thumbnail: CDN_URL + practices_path + '/crop_before.png',
            videoLink: CDN_URL + practices_path + '/crop_before.mp4',
            title: 'Crop Residue Management',
        },
    },
    {
        title: 'Reduced Till',
        before: {
            thumbnail: CDN_URL + practices_path + '/till_after.png',
            videoLink: CDN_URL + practices_path + '/till_after.mp4',
            title: 'Conventional Tillage',
        },
        after: {
            thumbnail: CDN_URL + practices_path + '/till_before.png',
            videoLink: CDN_URL + practices_path + '/till_before.mp4',
            title: 'Reduced Tillage',
        },
    },
];

export const sdgs = [
    '/no_poverty.png',
    '/hunger.png',
    '/health.png',
    '/education.png',
    '/gender.png',
    '/cleanwater.png',
    '/economy.png',
    '/industry.png',
    '/inequalities.png',
    '/sustainable.png',
    '/responsible.png',
    '/climate.png',
    '/land.png',
    '/partnerships.png',
].map((e) => CDN_URL + ourProjectsPath + '/sdg' + e);

/*************************************************************** */

/*************************************************************** */
// 2.content for Regen Gujarat

/*************************************************************** */

/*************************************************************** */
// 3.content for Agro Nepal
const nepal_sdg_path = ourProjectsPath + '/project_02/impact';
export const nepalSdg = [
    {
        title: 'No Poverty',
        subtitle:
            'The project initiatives aim to enhance the well-being of economically disadvantaged smallholder farming communities, thereby elevating their income levels over the course of the projects duration. This concerted effort serves as a catalyst in propelling these communities beyond the poverty line.',
        backgroundImage: CDN_URL + nepal_sdg_path + '/no_poverty.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/no_poverty.png',
    },
    {
        title: 'Zero Hunger',
        subtitle:
            'The project plants various trees, boosting soil health and productivity, tackling hunger and food security. It starts with 2500 farmers adopting afforestation for sustainable land use, aims to reach over 100,000 farmers, and provides training in climate-resilient strategies to protect plantations from natural hazards.',
        backgroundImage: CDN_URL + nepal_sdg_path + '/no_hunger.jpeg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/hunger.png',
    },

    {
        title: 'Quality Education',
        subtitle:
            'The project includes over 175 training sessions to improve understanding of climate change, its impacts, and adaptation and mitigation strategies, particularly in agriculture.',
        backgroundImage: CDN_URL + nepal_sdg_path + '/education.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/education.png',
    },
    {
        title: 'Gender Equality',
        subtitle:
            'The project actively creates an environment that empowers and educates women and girls, enhancing local employment and ensuring gender equality in all project activities.',
        backgroundImage: CDN_URL + nepal_sdg_path + '/gender.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/gender.png',
    },
    {
        title: 'Decent Work & Economic Growth',
        subtitle:
            'The project in Nepal offers fair, inclusive employment with equal pay, engaging over 50 workers and addressing youth unemployment by training and hiring over 20 young individuals, with plans for further expansion in rural areas.',
        backgroundImage: CDN_URL + nepal_sdg_path + '/economy.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/economy.png',
    },
    {
        title: 'Reduced Inequalities',
        subtitle:
            'Focusing on marginalized communities, the project promotes afforestation to enhance environmental sustainability and economic growth, providing training and resources to diversify income through sustainable agriculture and tree-based businesses.',
        backgroundImage: CDN_URL + nepal_sdg_path + '/reduced_inequality.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/inequalities.png',
    },
    {
        title: 'Responsible Consumption & Production',
        subtitle:
            'Advocating sustainable practices, the project plans to onboard 20,000 farmers to reduce water usage and improve soil health, educating them on sustainable development principles and environmental conservation for a balanced interaction with nature.',
        backgroundImage:
            CDN_URL + nepal_sdg_path + '/responsible_Consumption.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/responsible.png',
    },
    {
        title: 'Climate Action',
        subtitle:
            'The project aims to remove over 5.2 million tonnes of CO₂ through afforestation on 35,000 hectares and conducts training sessions on climate change dynamics and carbon management practices.',
        backgroundImage: CDN_URL + nepal_sdg_path + '/climate.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/climate.png',
    },
    {
        title: 'Life on Land',
        subtitle:
            'Initiatives include planting indigenous fruit trees on degraded lands and integrating sustainable land use strategies for biodiversity preservation, focusing on ecosystem-conscious land management.',
        backgroundImage: CDN_URL + nepal_sdg_path + '/land.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/land.png',
    },
    {
        title: 'Partnership for the Goals',
        subtitle:
            'Collaborations with international investors, NGOs, FPOs, and corporations secure financial resources and exchange knowledge and technology, advancing the pursuit of Sustainable Development Goals through shared expertise.',
        backgroundImage: CDN_URL + nepal_sdg_path + '/partner.jpeg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/partnerships.png',
    },
];

/*************************************************************** */

/*************************************************************** */
// 4.content for Agro Bangladesh

/*************************************************************** */

/*************************************************************** */
// 5.content for Biochar Gujarat

/*************************************************************** */

/*************************************************************** */
// 6.South india
const southIndia_sdg_path = ourProjectsPath + '/project_06/impact';
export const southIndiaSdg = [
    {
        title: 'No Poverty',
        subtitle:
            'The project aids low-income tribal communities in increasing income and overcoming poverty by enhancing overall well-being and addressing multi-dimensional poverty in the region.',
        backgroundImage: CDN_URL + southIndia_sdg_path + '/no_poverty.webp',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/no_poverty.png',
    },
    {
        title: 'Zero Hunger',
        subtitle:
            'Planting diverse trees improves soil health and farm productivity, addressing hunger issues across the state. Over 7500 farmers are involved in sustainable afforestation, with plans to reach over a lakh farmers for climate adaptation training.',
        backgroundImage: CDN_URL + southIndia_sdg_path + '/no_hunger.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/hunger.png',
    },

    {
        title: 'Quality Education',
        subtitle:
            'The project includes over 1500 training sessions to educate participants on climate change, its impacts on agriculture, and adaptation and mitigation strategies.',
        backgroundImage: CDN_URL + southIndia_sdg_path + '/education.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/education.png',
    },
    {
        title: 'Gender Equality',
        subtitle:
            'Focusing on gender equality, the project creates an environment that empowers women and girls, employing women engineer interns to assist in farmer onboarding, ensuring equal opportunities for all genders.',
        backgroundImage: CDN_URL + southIndia_sdg_path + '/gender.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/gender.png',
    },
    {
        title: 'Clean Water & Sanitation',
        subtitle:
            'The initiative enhances water quality by reducing fertilizer use and promoting eco-friendly management practices, contributing to clean water and sanitation goals.',
        backgroundImage: CDN_URL + southIndia_sdg_path + '/cleanwater.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/cleanwater.png',
    },
    {
        title: 'Decent Work & Economic Growth',
        subtitle:
            'In Andhra Pradesh, we offer fair employment to both men and women, ensuring equal pay and currently employing over 30 people. Addressing youth unemployment, we have trained and hired over 20 youths and plan to employ more in rural areas.',
        backgroundImage: CDN_URL + southIndia_sdg_path + '/economy.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/economy.png',
    },
    {
        title: 'Reduced Inequalities',
        subtitle:
            'We have trained and recruited over 20 young individuals in Andhra Pradesh, aiming to hire more youths from rural communities as we expand to other districts, thus reducing inequalities.',
        backgroundImage:
            CDN_URL + southIndia_sdg_path + '/reduced_inequality.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/inequalities.png',
    },
    {
        title: 'Responsible Consumption & Production',
        subtitle:
            'The project encourages sustainable practices to reduce water usage and improve soil health, aiming to onboard over 75,000 farmers. Training and awareness programs promote sustainable development and harmonious nature relationships.',
        backgroundImage:
            CDN_URL + southIndia_sdg_path + '/responsible_Consumption.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/responsible.png',
    },
    {
        title: 'Climate Action',
        subtitle:
            'By promoting afforestation on 75,000 hectares, the project aims to remove over 10 million tonnes of CO₂. Over 1500 training sessions on climate change and carbon management are planned.',
        backgroundImage: CDN_URL + southIndia_sdg_path + '/climate.png',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/climate.png',
    },
    {
        title: 'Life on Land',
        subtitle:
            'We are planting native fruit trees on barren lands to reduce biodiversity loss and strengthen habitat conservation, incorporating sustainable land use for biodiversity.',
        backgroundImage: CDN_URL + southIndia_sdg_path + '/land.webp',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/land.png',
    },
    {
        title: 'Partnership for the Goals',
        subtitle:
            'Collaborating with global investors, NGOs, and FPOs, we are mobilizing financial resources and sharing knowledge and technology to achieve the Sustainable Development Goals.',
        backgroundImage: CDN_URL + southIndia_sdg_path + '/partner.jpg',
        sdg: CDN_URL + ourProjectsPath + '/sdg' + '/partnerships.png',
    },
];
/*************************************************************** */
