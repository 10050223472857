import Banner from '../../modules/molecules/banner';
import thumbnail from '@thumbnailsAssets/ourprojects.jpg';
import ProjectList from './projectList';
import { useEffect } from 'react';
function OurProjects() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <section className="overflow-hidden bg-[#004d4a]">
            <Banner
                heading="Planetary-scale mitigation of climate change"
                subHeading={
                    <>
                        Varaha leverages a diversity of carbon removal pathways
                        in our projects across Asia
                    </>
                }
                videoLink="https://dfrlze9c0ze95.cloudfront.net/ourprojects.mp4"
                thumbnail={thumbnail}
                pb="20"
            />

            <ProjectList />
        </section>
    );
}

export default OurProjects;
