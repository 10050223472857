import { CDN_URL } from '../../config';

const climate_finance = /climate_finance/;

export const climateFinaceMembers = [
    {
        name: 'Ikarus Janzen',
        designation: 'Chief Commercial Officer',
        displayPic: CDN_URL + climate_finance + 'Ikarus Janzen.png',
        desc: '',
    },
    {
        name: 'Kurt Tsuo',
        designation: 'Chief Business Officer',
        displayPic: CDN_URL + climate_finance + 'Kurt Tsuo.png',
    },
    {
        name: 'Thomas Bennett',
        designation: 'Commercial Lead',
        displayPic: CDN_URL + climate_finance + 'Thomas Bennett.png',
        desc: '',
    },
    {
        name: 'Anoushka Gupta',
        designation: 'Manager - Climate Finance',
        displayPic: CDN_URL + climate_finance + 'Anoushka Gupta.png',
        desc: '',
    },
    {
        name: 'Gauraang Biyani',
        designation: 'Lead - Founders Office',
        displayPic: CDN_URL + '/founders_office/Gauraang Biyani.png',
        desc: '',
    },
];

export const techMembers = [
    {
        name: 'Kirankumar G',
        designation: 'Director - Engineering',
        displayPic: CDN_URL + '/tech/Kirankumar G.png',
        desc: '',
    },

    {
        name: 'Chirag Gupta',
        designation: 'Senior Product Manager',
        displayPic: CDN_URL + '/tech/Chirag Gupta.png',
        desc: '',
    },

    {
        name: 'Kunal Kumar',
        designation: 'SDE II - Backend',
        displayPic: CDN_URL + '/tech/Kunal Kumar.png',
        desc: '',
    },
    {
        name: 'Mohammed Suhaib Kazi',
        designation: 'SDE II - Mobile',
        displayPic: CDN_URL + '/tech/Mohammed Suhaib Kazi.png',
        desc: '',
    },
    {
        name: 'Aman Kumar',
        designation: 'SDE I - Backend',
        displayPic: CDN_URL + '/tech/Aman Kumar.png',
        desc: '',
    },
    {
        name: 'Abhishek M Katti',
        designation: 'SDE I - Frontend',
        displayPic: CDN_URL + '/tech/Abhishek M Katti.png',
        desc: '',
    },
    {
        name: 'Saketh Kumar Peddi',
        designation: 'SDE I - Backend',
        displayPic: CDN_URL + '/tech/Saketh Kumar Peddi.png',
        desc: '',
    },
    {
        name: 'Pranathi Pellkuru',
        designation: 'SDE I - Mobile',
        displayPic: CDN_URL + '/tech/Pranathi Pellkuru.png',
        desc: '',
    },
    {
        name: 'Ravish Mallya',
        designation: 'SDE I - Backend',
        displayPic: CDN_URL + '/tech/Ravish Mallya.png',
        desc: '',
    },
    {
        name: 'Gunjan Sahu',
        designation: 'SDE I - Data ',
        displayPic: CDN_URL + '/tech/Gunjan Sahu.png',
        desc: '',
    },
    {
        name: 'Rohit Singh',
        designation: 'Associate Product Manager',
        displayPic: CDN_URL + '/tech/Rohit Singh.png',
        desc: '',
    },
    {
        name: 'Goushik Ganesan',
        designation: 'UX/UI Designer',
        displayPic: CDN_URL + '/tech/Goushik Ganesan.png',
        desc: '',
    },
];
export const remoteSensingMembers = [
    {
        name: 'Mitali Chandnani, PhD',
        designation: 'Lead - GIS and Remote Sensing',
        displayPic: CDN_URL + '/science/Dr Mitali Chandnani.png',
        desc: '',
    },
    {
        name: 'Aravind S',
        designation: 'Principal Engineer',
        displayPic: CDN_URL + '/tech/Aravind S.png',
        desc: '',
    },
    {
        name: 'T. Rajasivaranjan',
        designation: 'Principal Engineer - Data Science',
        displayPic: CDN_URL + '/tech/Raja.png',
        desc: '',
    },

    {
        name: 'Janardan Roy',
        designation: 'Senior Geospatial Data Scientist',
        displayPic: CDN_URL + '/science/Janardan Roy.png',
        desc: '',
    },
    {
        name: 'Rijul Kumar',
        designation: 'Machine Learning Engineer',
        displayPic: CDN_URL + '/science/Rijul Kumar.png',
        desc: '',
    },
    {
        name: 'Rajani A',
        designation: 'Geospatial Data Scientist',
        displayPic: CDN_URL + '/science/Rajani A.png',
        desc: '',
    },
    {
        name: 'Reona Fernandes',
        designation: 'Geospatial Data Scientist',
        displayPic: CDN_URL + '/science/Reona Fernandes.png',
        desc: '',
    },
];
export const dataQualityAssurance = [
    {
        name: 'Vrushali Adsul',
        designation: 'Data Quality Assurance Manager',
        displayPic: CDN_URL + '/tech/Vrushali.png',
        desc: '',
    },
    {
        name: 'Anjali Patyal',
        designation: 'Lead - Quality Assurance',
        displayPic: CDN_URL + '/tech/Anjali Patyal.png',
        desc: '',
    },
    {
        name: 'Sai Subrahmanyam Sriramagiri',
        designation: 'Junior Data Quality Officer, Carbon Projects',
        displayPic: CDN_URL + '/tech/Sai Subrahmanyam Sriramagiri.png',
        desc: '',
    },
    {
        name: 'Sumana Kundu',
        designation: 'Junior Data Quality and Assurance Associate',
        displayPic: CDN_URL + '/project_members/Sumana Kundu.png',
        desc: '',
    },
];
export const scienceMembers = [
    {
        name: 'Trung Nguyen, PhD',
        designation: 'Director of Science',
        displayPic: CDN_URL + '/science/Dr Trung Nguyen.png',
        desc: '',
    },
    {
        name: 'Suman Samanta, PhD',
        designation: 'Principal Scientist - Carbon Modeling',
        displayPic: CDN_URL + '/science/Dr Suman Samanta.png',
        desc: '',
    },

    {
        name: 'Pradeep Kumar Dash, PhD',
        designation: 'Scientist - Carbon Modeling',
        displayPic: CDN_URL + '/science/Dr. Pradeep Kumar Dash.png',
        desc: '',
    },

    {
        name: 'Rushil Hari Koppaka',
        designation: 'Research Associate - Soil Carbon Modelling',
        displayPic: CDN_URL + '/science/Rushil Hari Koppaka.png',
        desc: '',
    },
    {
        name: 'Jit Sankar Basak, PhD',
        designation: 'Actuarial Analyst',
        displayPic: CDN_URL + '/science/Jit Sankar Basak.png',
        desc: '',
    },
    {
        name: 'Dr.Soumya Ranjan Padhy',
        designation: 'Research Associate',
        displayPic: CDN_URL + '/project_members/Soumya Ranjan Padhy.png',
        desc: '',
    },
    {
        name: 'Akanksha Sharma',
        designation: 'Research Associate',
        displayPic: CDN_URL + '/project_members/Akanksha Sharma.png',
        desc: '',
    },
];
export const projectsMembers = [
    {
        name: 'Gayatri Dhumal',
        designation: 'Technical Manager - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Gayatri Dhumal.png',
        desc: '',
    },

    {
        name: 'Ashish Singh',
        designation: 'Manager- Carbon Projects',
        displayPic: CDN_URL + '/project_members/Ashish Singh.png',
        desc: '',
    },
    {
        name: 'Vikas Krushna Nagane',
        designation: 'Assistant Manager',
        displayPic: CDN_URL + '/project_members/Vikas Krushna Nagane.png',
        desc: '',
    },
    {
        name: 'Pranav Jain',
        designation: 'Associate Manager - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Pranav Jain.png',
        desc: '',
    },
    {
        name: 'Anurag Jain',
        designation: 'Associate Manager - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Anurag Jain.png',
        desc: '',
    },
    {
        name: 'Abhijeet Singh',
        designation: 'Associate Manager - Durable Removal',
        displayPic: CDN_URL + '/project_members/Abhijeet Singh.png',
        desc: '',
    },
    {
        name: 'Aditya Vikram Singh',
        designation: 'Associate - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Aditya Vikram Singh.png',
        desc: '',
    },
    {
        name: 'Prakhar Khare',
        designation: 'Associate - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Prakhar Khare.png',
        desc: '',
    },
    {
        name: 'Preety Priya',
        designation: 'Associate - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Preety Priya.png',
        desc: '',
    },
    {
        name: 'Aanchal choudhary',
        designation: 'Associate - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Aanchal choudhary.png',
        desc: '',
    },
    {
        name: 'Alka Ojha',
        designation: 'Associate - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Alka.png',
        desc: '',
    },
    {
        name: 'Tejavath Manohar Naik',
        designation: 'Associate - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Tejavath Manohar Naik.png',
        desc: '',
    },
    {
        name: 'Garima Bhatt',
        designation: 'Associate - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Garima Bhatt.png',
        desc: '',
    },
    {
        name: 'Smriti Joshi',
        designation: 'Associate - Carbon Projects',
        displayPic: CDN_URL + '/project_members/Smriti Joshi.png',
        desc: '',
    },
];

export const operationMembers = [
    {
        name: 'Aditya Kajalia',
        designation: 'Lead - Carbon Supply',
        displayPic: CDN_URL + '/operations/Aditya Kajalia.png',
        desc: '',
    },
    {
        name: 'Vendrapati Srinivasa Rao',
        designation: 'Senior Manager - Carbon Supply',
        displayPic: CDN_URL + '/project_members/VENDRAPATI SRINIVASA RAO.png',
        desc: '',
    },
    {
        name: 'Ranjeet Thapa',
        designation: 'Manager Partnership & Carbon Supply',
        displayPic: CDN_URL + '/partnerships/Ranjeet_Thapa.png',
    },
    {
        name: 'Naren kumar',
        designation: 'Regional Manager',
        displayPic: CDN_URL + '/operations/Naren kumar.png',
        desc: '',
    },
    {
        name: 'Sandeep Kumar Tiwari',
        designation: 'Regional Manager',
        displayPic: CDN_URL + '/operations/Sandeep Kumar Tiwari.png',
        desc: '',
    },
    {
        name: 'Nilmani Prakash,Phd',
        designation: 'Regional Coordinator',
        displayPic: CDN_URL + '/operations/Nilmani Prakash.png',
        desc: '',
    },
    {
        name: 'Mohit Kushwah',
        designation: 'State Lead',
        displayPic: CDN_URL + '/operations/Mohit Kushwah.png',
        desc: '',
    },
    {
        name: 'Abhishek Burman',
        designation: 'Manager - Durable Removal',
        displayPic: CDN_URL + '/operations/Abhishek Burman.png',
        desc: '',
    },
    {
        name: 'Akhil Malhotra',
        designation: 'Manager - Training and Data Quality',
        displayPic: CDN_URL + '/operations/Akhil Malhotra.png',
        desc: '',
    },
    {
        name: 'Lavate Mahesh Gorakh',
        designation: 'Manager - Plantations',
        displayPic: CDN_URL + '/operations/Lavate Mahesh Gorakh.png',
        desc: '',
    },
    {
        name: 'Dinesh Kumar',
        designation: 'Territory Manager',
        displayPic: CDN_URL + '/operations/Dinesh Kumar.png',
        desc: '',
    },
    {
        name: 'Dheeraj Bhatt',
        designation: 'Territory Manager',
        displayPic: CDN_URL + '/operations/Dheeraj Bhatt.png',
        desc: '',
    },
    {
        name: 'Shubham Verma',
        designation: 'Associate - Carbon Supply',
        displayPic: CDN_URL + '/operations/Shubham Verma.png',
        desc: '',
    },
    {
        name: 'Aditya Kumar Suman',
        designation: 'Associate - Carbon Supply',
        displayPic: CDN_URL + '/project_members/Aditya Kumar Suman.png',
        desc: '',
    },
    {
        name: 'Akshay Mohan Chadda',
        designation: 'Associate - Carbon Projects',
        displayPic: CDN_URL + '/operations/Akshay Mohan Chadda.png',
        desc: '',
    },
    {
        name: 'Parmod Kumar',
        designation: 'Area Manager',
        displayPic: CDN_URL + '/operations/Parmod Kumar.png',
        desc: '',
    },
    {
        name: 'Bhupinder Singh Rana',
        designation: 'Assistant Business Development Officer',
        displayPic: CDN_URL + '/operations/Bhupinder Singh Rana.png',
        desc: '',
    },
    {
        name: 'Monu Chhaba',
        designation: 'Assistant Territory Manager',
        displayPic: CDN_URL + '/operations/Monu Chhaba.png',
        desc: '',
    },
    {
        name: 'Dadda Katyayan',
        designation: 'Assistant Territory Manager',
        displayPic: CDN_URL + '/operations/Dadda Katyayan.png',
        desc: '',
    },
    {
        name: 'Ravinder Chahal',
        designation: 'Assistant Territory Manager',
        displayPic: CDN_URL + '/operations/Ravinder Chahal.png',
        desc: '',
    },

    {
        name: 'Ashok Kumar',
        designation: 'Senior Field Supervisor',
        displayPic: CDN_URL + '/operations/Ashok Kumar.png',
        desc: '',
    },
    {
        name: 'Noor Mohammed Jat',
        designation: 'Field Supervisor',
        displayPic: CDN_URL + '/operations/Noor Mohammed Jat.png',
        desc: '',
    },
];

export const partnerShipMembers = [
    {
        name: 'Kaushal Bisht',
        designation: 'Lead - Partnerships',
        displayPic: CDN_URL + '/partnerships/Kaushal Bisht.png',
        desc: '',
    },
    {
        name: 'Gayatri Dhumal',
        designation: 'Technical Manager',
        displayPic: CDN_URL + '/project_members/Gayatri Dhumal.png',
        desc: '',
    },
    {
        name: 'Abhishek Sharma',
        designation: 'Manager - Partnerships',
        displayPic: CDN_URL + '/partnerships/Abhishek Sharma.png',
        desc: '',
    },
    {
        name: 'M S N Murthy',
        designation: 'Manager - Partnerships',
        displayPic: CDN_URL + '/partnerships/M S N Murthy.png',
        desc: '',
    },
    {
        name: 'Leela Prasad Nadendla',
        designation: 'Manager - CCB',
        displayPic: CDN_URL + '/science/Leela Prasad Nadendla.png',
        desc: '',
    },
    {
        name: 'Ashish Singh, PhD',
        designation: 'Manager',
        displayPic: CDN_URL + '/project_members/Ashish Singh.png',
        desc: '',
    },
    {
        name: 'Mercy Nzuve',
        designation: 'Program Manager',
        displayPic: CDN_URL + '/project_members/Mercy.png',
        desc: '',
    },
    {
        name: 'Vikas Krushna Nagane',
        designation: 'Assistant Manager',
        displayPic: CDN_URL + '/project_members/Vikas Krushna Nagane.png',
        desc: '',
    },
    {
        name: 'Pranav Jain',
        designation: 'Associate Manager',
        displayPic: CDN_URL + '/project_members/Pranav Jain.png',
        desc: '',
    },
    {
        name: 'Anurag Jain, PhD',
        designation: 'Associate Manager',
        displayPic: CDN_URL + '/project_members/Anurag Jain.png',
        desc: '',
    },
    {
        name: 'Abhijeet Singh',
        designation: 'Associate Manager - Durable Removal',
        displayPic: CDN_URL + '/project_members/Abhijeet Singh.png',
        desc: '',
    },
    {
        name: 'Kanchan Yadav',
        designation: 'Senior Associate - Partnerships',
        displayPic: CDN_URL + '/partnerships/Kanchan Yadav.png',
        desc: '',
    },
    {
        name: 'Aditya Vikram Singh',
        designation: 'Associate',
        displayPic: CDN_URL + '/project_members/Aditya Vikram Singh.png',
        desc: '',
    },

    {
        name: 'Prakhar Khare',
        designation: 'Associate',
        displayPic: CDN_URL + '/project_members/Prakhar Khare.png',
        desc: '',
    },
    {
        name: 'Preety Priya',
        designation: 'Associate',
        displayPic: CDN_URL + '/project_members/Preety Priya.png',
        desc: '',
    },
    {
        name: 'Aanchal Choudhary',
        designation: 'Associate',
        displayPic: CDN_URL + '/project_members/Aanchal choudhary.png',
        desc: '',
    },
    {
        name: 'Alka Ojha',
        designation: 'Associate',
        displayPic: CDN_URL + '/project_members/Alka.png',
        desc: '',
    },
    {
        name: 'Tejavath Manohar Naik',
        designation: 'Associate',
        displayPic: CDN_URL + '/project_members/Tejavath Manohar Naik.png',
        desc: '',
    },
    {
        name: 'Garima Bhatt',
        designation: 'Associate',
        displayPic: CDN_URL + '/project_members/Garima Bhatt.png',
        desc: '',
    },
    {
        name: 'Smriti Joshi',
        designation: 'Associate',
        displayPic: CDN_URL + '/project_members/Smriti Joshi.png',
        desc: '',
    },
];

export const adminFinanceLegal = [
    {
        name: 'Dhaneshwar Gupta',
        designation: 'Lead - Finance',
        displayPic: CDN_URL + '/admin_finance/Dhaneshwar Gupta.png',
        desc: '',
    },
    {
        name: 'Kevin R. Lawrence',
        designation: 'Associate Lead - Human Resource',
        displayPic: CDN_URL + '/aboutUs/members/Kevin.png',
        desc: '',
    },
    {
        name: 'Kulbeer Singh',
        designation: 'Manager - Finance',
        displayPic: CDN_URL + '/admin_finance/Kulbeer Singh.png',
        desc: '',
    },
    {
        name: 'Aniket Kumar',
        designation: 'Manager - Marketing',
        displayPic: CDN_URL + '/marketing/Aniket Kumar.png',
        desc: '',
    },
    {
        name: 'Harsh Dubey',
        designation: 'Manager - Administration',
        displayPic: CDN_URL + '/operations/Harsh Dubey.png',
        desc: '',
    },
    {
        name: 'Shivam Gupta',
        designation: 'Assistant Manager - Finance',
        displayPic: CDN_URL + '/admin_finance/Shivam Gupta.png',
        desc: '',
    },
    {
        name: 'Muskan Singh',
        designation: 'HR Associate',
        displayPic: CDN_URL + '/admin_finance/Muskan Singh.png',
        desc: '',
    },
    {
        name: 'Princy Arya',
        designation: 'Graphic Designer',
        displayPic: CDN_URL + '/marketing/Princy Arya.png',
        desc: '',
    },
];
