import React from 'react';
import './perks.css';
import robust from '@careerAssets/perks/01_Robust Career Growth.png';
import family from '@careerAssets/perks/02_Family Health Insurance.png';
import culture from '@careerAssets/perks/03_Flexible Work Culture& Sabbaticals.png';
import nodress from '@careerAssets/perks/04_No Dress Code Nonsense.png';
import esops from '@careerAssets/perks/05_ESOPs for all.png';
import folks from '@careerAssets/perks/06_Daily Acquaintance with Like-Minded Folks.png';
import perks from '@careerAssets/perks.png';

const Perks = () => {
    return (
        <>
            <section className="bg-primarydark">
                <div className="perks-wrapper lg:h-screen">
                    <div className="perks-img ">
                        <img className="w-[95%] " src={perks} alt="" />
                    </div>
                    <div className="health-insurance ">
                        <h2 className="h2 lg:w-96 w-auto font-medium text-gradient items-center leading-tight pt-4 lg:pt-0">
                            Perks of being a Varahan
                        </h2>
                        <div className="rubust-wrapper">
                            <div className=" lg:w-56">
                                <img className="w-10" src={robust} alt="" />
                                <h6 className="h6 text-white  mt-3">
                                    Robust Career Growth
                                </h6>
                            </div>
                            <div className="lg:w-56 mt-10 lg:mt-0">
                                <img className="w-10" src={family} alt="" />
                                <h6 className="h6 text-white  mt-3">
                                    Family Health Insurance
                                </h6>
                            </div>
                        </div>
                        <div className="rubust-wrapper">
                            <div className="lg:w-56">
                                <img className="w-10" src={culture} alt="" />
                                <h6 className="h6 text-white  mt-3">
                                    Flexible Work Culture & Sabbaticals
                                </h6>
                            </div>
                            <div className=" lg:w-56 mt-10 lg:mt-0">
                                <img className="w-10" src={nodress} alt="" />
                                <h6 className="h6 text-white  mt-3">
                                    No Dress Code Nonsense
                                </h6>
                            </div>
                        </div>
                        <div className="rubust-wrapper">
                            <div className="lg:w-56">
                                <img className="w-10" src={esops} alt="" />
                                <h6 className="h6 text-white  mt-3  ">ESOPs</h6>
                            </div>
                            <div className=" lg:w-56 mt-10 lg:mt-0">
                                <img className="w-10 " src={folks} alt="" />
                                <h6 className="h6 text-white  mt-3">
                                    Daily Acquaintance with Like-Minded Folks
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Perks;

/* <section className="perks-main-wrapper bg-primary">
        <div className="perks-wrapper container d">
          <div></div>
          <div className="  ">
            <h2 className="h2 w-96 text-gradient">Perks of being a Varahan</h2>
            <div className="robust_wrapper my-14   ">
              <div className="w-28  ">
                <img className="w-10" src={robust} alt="" />
                <h6 className="text-white">
                  Robust Career <br></br>Growth
                </h6>
              </div>
              <div className="w-48  ">
                <img className="w-10" src={robust} alt="" />
                <h6 className="text-white ">
                  Family Health<br></br> Insurance
                </h6>
              </div>
            </div>
            <div className="robust_wrapper my-10">
              <div className="w-44">
                <img className="w-10" src={robust} alt="" />
                <h6 className="text-white">
                  Flexible Work Culture <br></br>& Sabbaticals
                </h6>
              </div>
              <div className="w-48">
                <img className="w-10" src={robust} alt="" />
                <h6 className="text-white ">
                  No Dress Code<br></br> Nonsense
                </h6>
              </div>
            </div>
            <div className="robust_wrapper my-10">
              <div className="w-44">
                <img className="w-10" src={robust} alt="" />
                <h6 className="text-white ">ESOPs for all</h6>
              </div>
              <div className="w-48  ">
                <img className="w-10" src={robust} alt="" />
                <h6 className="text-white">
                  Daily Acquaintance <br></br>with Like-Minded Folks
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section> */
