import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Footer } from '../modules/molecules';
import {
    AboutScreen,
    ComingSoonScreen,
    Error404Screen,
    ForBusinessScreen,
    HomeScreen,
    CareerScreen,
    OurTechScreen,
    ForPartnersScreen,
    DisclaimerScreen,
    OurProjects,
    Funding,
} from '../screens';
import {
    AgroBangladesh,
    AgroNepal,
    AgroSouth,
    BioChar,
    RegenGujarat,
    IndoGangetic,
    ERW,
    PuroTelangana,
} from '../screens/ourProjects/projects';

const ViewWithNavAndFooter = () => {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    );
};

const routes = () => {
    return [
        {
            path: '/',
            element: <ViewWithNavAndFooter />,
            children: [
                { path: '/', element: <HomeScreen /> },
                {
                    path: '/about-us',
                    element: <AboutScreen />,
                },
                {
                    path: '/coming-soon',
                    element: <ComingSoonScreen />,
                },
                {
                    path: '/careers',
                    element: <CareerScreen />,
                },
                {
                    path: '/our-tech',
                    element: <OurTechScreen />,
                },
                // {
                //     path: '/for-businesses',
                //     element: <ForBusinessScreen />,
                // },
                // {
                //     path: '/for-partners',
                //     element: <ForPartnersScreen />,
                // },
                {
                    path: '/disclaimer',
                    element: <DisclaimerScreen />,
                },
                {
                    path: '/funding',
                    element: <Funding />,
                },
                {
                    path: '/ourProjects',
                    element: <OurProjects />,
                },
                {
                    path: 'ourProjects/indo-gangetic',
                    element: <IndoGangetic />,
                },
                {
                    path: 'ourProjects/regen-gujarat',
                    element: <RegenGujarat />,
                },
                {
                    path: 'ourProjects/agro-nepal',
                    element: <AgroNepal />,
                },
                {
                    path: 'ourProjects/agro-bangladesh',
                    element: <AgroBangladesh />,
                },
                {
                    path: 'ourProjects/biochar-gujarat',
                    element: <BioChar />,
                },
                {
                    path: 'ourProjects/arr-southindia',
                    element: <AgroSouth />,
                },
                {
                    path: 'ourProjects/erw-madhya-pardesh',
                    element: <ERW />,
                },
                // {
                //     path: 'ourProjects/puro-industrial-glasifier-telangana',
                //     element: <PuroTelangana />,
                // },
            ],
        },
        {
            path: '*',
            element: <Error404Screen />,
        },
    ];
};

export default routes;
